html 
{
    overflow: auto;
    font-size: 16px;
}

body
{
    background-color: rgb(150, 150, 150);
    margin: 0;
}
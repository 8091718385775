.App
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.App > .CenterApp
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1vh;   
    height: 100vh; 
}

.Builds_List
{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Build_In_BuildsList
{
    display: flex;
    background-color: rgb(114, 114, 114);
}

.Set_Build 
{
    position: relative;
    right: 0px;
    top: 20px;
    width: 0;
	height: 0;
    padding: 0;
    border-left: 0px;
	border-right: 28px solid rgb(10, 104, 0);
    border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
    margin-left: 30px;
    background-color: rgb(114, 114, 114);
}

.Set_Build:hover 
{
    opacity: 0.5;
}

.Suppress_Build 
{
    position: relative;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    margin-right: 20px;
    border: 0px;
    padding: 0px;
    background-color: rgb(114, 114, 114);
}

.Suppress_Build:hover
{
    opacity: 0.5;
}

.Suppress_Build:before, .Suppress_Build:after 
{
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 4px;
    border-radius: 25%;
    background-color: rgb(99, 0, 0);
}

.Suppress_Build:before 
{
    transform: rotate(-45deg);
}

.Suppress_Build:after 
{
    transform: rotate(45deg);
}

.Add_Build 
{
    position: relative;
    right: -28px;
    top: 0px;
    width: 32px;
    height: 32px;
    margin-right: 20px;
    border: 0px;
    padding: 0px;
    background-color: rgb(114, 114, 114);
}

.Add_Build:hover
{
    opacity: 0.5;
}

.Add_Build:before, .Add_Build:after 
{
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 4px;
    border-radius: 25%;
    background-color: rgb(10, 104, 0);
}

.Add_Build:after 
{
    transform: rotate(90deg);
}

.Selected_Build 
{
    position: relative;
    right: 0px;
    top: 20px;
    width: 0;
	height: 0;
    padding: 0;
    border-right: 0px;
	border-left: 28px solid rgb(0, 2, 104);
    border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
    margin-left: 30px;
    background-color: rgb(114, 114, 114);
}
.MultiRangeSlider
{
    width: 230px;
}

.slider_MultiRangeSlider
{
    position: relative;
    width: 100%;
    height: 8px;
    background: rgb(105, 105, 105);
    border-radius: 4px;
}

.slider_MultiRangeSlider input
{
    transform: translate(0, -60%);
    top: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 4;
    width: 230px;
    height: 20px;

    opacity: 0;
}

.thumb_MultiRangeSlider 
{
    transform: translate(0, -50%);
    top: 50%;

    position: absolute;
    z-index: 3;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgb(54, 54, 54);
}

.range_MultiRangeSlider
{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgb(54, 54, 54);
}


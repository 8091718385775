.ItemsSelected
{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgb(114, 114, 114);
    z-index: 10;
    margin-left: 30px;
    margin-right: 5px;
}
.SelectSort
{
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin: 5px;
}

.SortSelection
{
    width: 75px;
    height: 35px;
}

.SelectSort > .SortSelection > button
{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
    width: 75px;
    height: 30px;
    background-color: rgb(73, 73, 73);
    font-size: 15px;
}

.SelectSort > .SortSelection > button:hover + .tooltiptext
{
    visibility: visible;
}

.SelectSort > .SortSelection > .tooltiptext 
{
    visibility: hidden;

    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 8px;
    border-radius: 6px;
   
    position: relative;
    z-index: 1;    
    bottom: 150%;
}

.arrow 
{
    background: rgb(163, 163, 163);
    height: 3px;
    width: 20px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}
.arrow:before, .arrow:after 
{
    content: "";
    background: rgb(163, 163, 163);
    position: absolute;
    height: 3px;
    width: 11px;
}
  
.arrow:before 
{
    right: -2px;
    bottom: -3px;
    transform: rotate(-45deg);
}
  
.arrow:after 
{
    right: -2px;
    top: -3px;
    transform: rotate(45deg);
}

.arrow_down 
{
    transform: rotate(45deg);
}

.arrow_up 
{
    transform: rotate(-45deg);
}
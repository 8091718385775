.HeaderAndDialog 
{
    --HeaderSizeDialog: 230px;
}

.Header
{
    background-color: rgb(114, 114, 114);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 10vh;
    margin-bottom: 10px;
}

.Header > *
{
    margin-left: 1vw;
}

.Builds
{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15px;
    left: 30vw;
    margin-bottom: 20px;
    background-color: rgb(114, 114, 114);
}

dialog
{
    background-color: rgb(114, 114, 114);
    margin-top: 12vh;
    width: var(--HeaderSizeDialog);
    height: var(--HeaderSizeDialog);
    padding: 30px;
    border: 5px solid rgb(194, 194, 194);
    border-radius: 5%;
}

.Dialog
{
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 15;
}

.HeaderAndDialog button
{
    background-color: rgb(138, 138, 138);
    border: none;
    padding: 4px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
}

form > p > input
{
    background-color: rgb(226, 226, 226);
    font-size: 16px;
    width: var(--HeaderSizeDialog);
}

.HeaderAndDialog button:hover
{
    background-color: rgb(194, 194, 194);
}

.Close_Dialog_Div_Login
{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.Close_Dialog_Div_Registration
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Dialog_Button
{
    width: 160px;
}

#Registration_Button
{
    margin-top: 31px;
}

.Close_Button
{
    display: flex;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
}

.Close 
{
    position: absolute;

    right: 41px;
    top: 33px;

    width: 16px;
    height: 16px;
}

.Close:before, .Close:after 
{
    position: absolute;
    left: 15px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: black;
}

.Close:before 
{
    transform: rotate(45deg);
}

.Close:after 
{
    transform: rotate(-45deg);
}
  
.Arrow
{
    position: absolute;
    left: 38px;
    top: 36px;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;

    transform: rotate(135deg);  
}
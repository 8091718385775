.ItemsTabViewPage
{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 6px;
    height: 80vh;
    width: 722px;
}

.ButtonPage_ItemTabView
{
    display: flex;
    justify-content: center;
}

.ButtonPage_ItemTabView > *
{
    margin: 10px 30px;

    background-color: rgb(105, 105, 105);
    border: none;
    height: 40px;
    width: 60px;
    border-radius: 15px;
}

.Page_ItemTabView
{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.ButtonPage_ItemTabView > button > div
{
    border: solid black;
    border-width: 0 2px 2px 0;
    position: relative;
    top: 2px;
    display: inline-block;
    padding: 6px;
    transition-duration: 1s;
}

.ButtonPage_ItemTabView > button:hover
{
    background-color: rgb(77, 77, 77);
}

.ArrowUp_ItemTabView
{
    right: 10%;
}
.ButtonPage_ItemTabView > button:hover >.ArrowUp_ItemTabView
{
    translate: 80%;
}

.ArrowDown_ItemTabView
{
    left: 10%;
}
.ButtonPage_ItemTabView > button:hover >.ArrowDown_ItemTabView
{
    translate: -80%;
}

.ArrowUp_ItemTabView
{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);     
}

.ArrowDown_ItemTabView
{
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.ItemTabView
{
    background-color:rgb(37, 37, 37);
    border-radius: 9px;
    display: flex;
    width: 680px;
    padding: 10px;
    gap: 10px;
}

.ItemTabView > .ImageButton_ItemTabView
{
    width: 115px;

    display: flex;
    flex-direction: column;
}

.ButtonBelowImageItem_ItemTabView
{
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    margin-top: 8px;
    gap: 5px;
}

.TooltipDiv_ItemTabView
{
    border-radius: 5px;
    height: 30px;
    width: 30px;
    background-color: rgb(73, 73, 73);
}

.TooltipDiv_ItemTabView > * > img
{
    height: 26px;
    width: 26px;
    margin: 2px;
}

.TooltipDiv_ItemTabView:hover
{
    background-color: rgb(105, 105, 105);
}

.ImageLink_ItemTabView
{
    height: 30px;
    width: 30px;
}

.ImageItem_ItemTabView
{
    height: 115px;
    width: 115px;
    border-radius: 15px;
    background-color: rgb(73, 73, 73);
    color: rgb(73, 73, 73);
}

.TooltipDiv_ItemTabView:hover .Tooltip_ItemTabView
{
    visibility: visible;
}

.Tooltip_ItemTabView
{
    visibility: hidden;

    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 8px;
    border-radius: 6px;
   
    position: relative;
    z-index: 1;    
    bottom: 200%;
}

.ItemStatView
{
    display: flex;
    flex-direction: column;
}

.ItemTabView > .ItemStatView > span
{
    min-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: aliceblue;
}

.ItemTabView > .ItemStatView > .IconAndName > span
{
    color: aliceblue;
}
.SelectRarity
{
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin: 5px;
}

.RaritySelection
{
    width: 40px;
    height: 40px;
}

.SelectRarity > .RaritySelection > button
{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
    width: 40px;
    height: 40px;
    background-color: rgb(73, 73, 73);
}

.SelectRarity > .RaritySelection > button:hover + .tooltiptext
{
    visibility: visible;
}

.SelectRarity > .RaritySelection > .tooltiptext 
{
    visibility: hidden;

    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 8px;
    border-radius: 6px;
   
    position: relative;
    z-index: 1;    
    bottom: 150%;
}

.RaritySelected
{
    opacity: 1;
}

.RarityDeselected
{
    opacity: 0.5;
}


.Menu
{
    position: absolute;
    top: 15vh;
    left: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Menu_Button
{
    border: 0px;
    background-color: rgb(77, 77, 77);
    border-radius: 0px 10px 10px 0px;
    padding: 8px 10px;
    display: flex;
justify-content: flex-start;
}

.Menu_Button:hover
{
    background-color: rgb(175, 175, 175);    
}

.Menu_Button_Selected
{
    border: 0px;
    background-color: rgb(150, 150, 150);  
    border-radius: 0px 10px 10px 0px;
    padding: 5px 10px;
    border: 3px solid rgb(77, 77, 77);
    border-left-width: 0;
    display: flex;
    justify-content: flex-start;
}

.Menu_Text
{
    font-size: 20px;
}


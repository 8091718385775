.ItemsFilter
{
    width: 350px;
}

.ItemsFilter > .Temp_ItemsFilter > input
{
    width: 40px;
}

.Button_ItemsFilter
{
    margin: 15px;
    background-color: rgb(114, 114, 114);
    border: none;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.Button_ItemsFilter:hover
{
    background-color: rgb(73, 73, 73);
}

.Button_ItemsFilter:active 
{
    background-color: rgb(73, 73, 73);
    transform: translateY(4px);
}
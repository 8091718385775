.ItemSelected
{
    width: 52px;
    height: 52px;
    margin: 5px;
    border: 2px solid rgb(77, 77, 77);
    background-color: rgb(209, 209, 209);
    overflow: hidden;    
}

.ItemSelected > img
{
    background-color: rgb(175, 175, 175);
}

.ImageItem_ItemSelected
{
    width: 52px;
    height: 52px;
    transform: scale(1.5);
}

.ImageType_ItemSelected
{
    width: 36px;
    height: 36px;
    margin: 8px;
}

.redOutLine
{
    outline: 5px solid red;
    border-radius: 4px;
}

.greenOutLine
{
    outline: 5px solid green;
    border-radius: 4px;
}
.StatsView
{   
    color: aliceblue;
    width: 440px;
}

.IconAndName
{
    display: flex;
    justify-content: flex-start;
    gap: 1px;
}

span
{
    white-space: nowrap;
}

.SecondaryStats .icon
{
    filter: contrast(0.1);
}

.StatsView > div
{
    display: flex;
    flex-wrap: wrap;
    width: 440px;
    gap: 4px;
    margin-bottom: 4px;
}

.StatsView > div > div
{
    display: flex;
    justify-content: space-between;
    background-color: rgb(49, 49, 49);
    width: 208px;
    padding: 5px 5px;
    border-radius: 5px;
}

.StatsView > .ElementalStats > div
{
    display: flex;
    justify-content: space-between;
    background-color: rgb(92, 92, 92);
    width: 218px;
    padding: 0px;
}

.StatsView > .ElementalStats > div > div
{
    display: flex;
    justify-content: space-between;
    background-color: rgb(49, 49, 49);
    border-radius: 5px;
    width: 97px;
    padding: 2px 5px;
}

.StatsView > .SecondaryStats
{
    display: flex;
    flex-wrap: wrap;
}
.StatLine
{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 565px;
}

.StatLine > span
{
    color: aliceblue;
}

.StatLine > .Positive
{
    color: green;
}

.StatLine > .Negative
{
    color: red;
}